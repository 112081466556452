import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import {
  ContentSection,
  ContentText,
  Headline,
  Layout,
  Map,
  SEO,
  Start,
  TextAndIcon,
  TextAndImage,
  CustomerLogos,
} from '@egonoid/gatsby-theme-common';

import { getLanguageFromLocation } from '@egonoid/gatsby-theme-common/src/common/util/language.util';

import Cloud from '../../../egonoid.com/static/assets/img/icons/cloud.svg';
import Mobile from '../../../egonoid.com/static/assets/img/icons/mobile.svg';
import Payment from '../../../egonoid.com/static/assets/img/icons/payment.svg';
import Brain from '../../../egonoid.com/static/assets/img/icons/brain.svg';
import Database from '../../../egonoid.com/static/assets/img/icons/database.svg';
import Monitoring from '../../../egonoid.com/static/assets/img/icons/monitoring.svg';

// import styles from './page.module.scss';

const shortcodes = {
  h1: Headline,
  Headline,
  ContentSection,
  ContentText,
  Map,
  Start,
  TextAndImage,
  TextAndIcon,
  Cloud,
  Mobile,
  Payment,
  Brain,
  Database,
  Monitoring,
  CustomerLogos,
};

interface IProps {
  data: any;
  location: any;
}

const Page: React.FC<IProps> = ({ data, location }) => {
  const siteTitle = data.mdx.frontmatter.title;
  const page = data.mdx;

  const language = getLanguageFromLocation(location);

  return (
    <Layout location={location} title={siteTitle} showLogo={true} language={language}>
      <SEO title={siteTitle} />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{page.body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        date
      }
      body
      fields {
        slug
      }
    }
  }
`;

export default Page;
