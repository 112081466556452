const getLanguageFromLocation = (location: { pathname: string } | null) => {
  let language = 'en';

  if (location?.pathname?.startsWith('/de')) {
    language = 'de';
  }

  return language;
};

export { getLanguageFromLocation };
